/**
 * instantinateThemeToggle
 * 
 * This function creates a closure to manage the state of the theme toggle switch, which handles changing between light and dark modes.
 * It stores two persistently scoped variables to track which mode is currently active, and a second variable if the mode is currently
 * in the middle of changing/in transition. Finally it sets up the 3 event listeners required for the theme toggle switch to function.
 * @returns {Object} Returns the themeToggle function
 */
function instantinateThemeToggle() {

    // Check if the user has dark mode set as the preference on their machine
    var isDarkModeActive = window.matchMedia('(prefers-color-scheme: dark)').matches;

    // Is there currently a transition between color modes happening
    var transitioningBetweenModes = false;

    // Wait for the DOM to load, then add event listeners
    document.addEventListener('DOMContentLoaded', function themeToggleDomLoaded() {

        // Check if the theme toggle element exists in the DOM
        if (document.getElementById('themeSwitch') !== null) {

            // Add an event listener to listen for user clicks on the theme toggle switch
            document.getElementById('themeSwitch').addEventListener('click', themeToggle);

            // Add an event listener to listen for the enter key press while the toggle switch is in focus
            document.getElementById('themeSwitch').addEventListener('keyup', themeToggle);

            // Add an event listener to determine if the user changes their color preference on their machine
            window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', themeToggle);

        }

    });

    /**
     * ThemeToggle
     * 
     * @param {Object} event An object that implements the Event interface that is passed to an event listener. This is only passed when an even listener adds this function to the call stack.
     * @returns void
     */
    function themeToggle(event) {

        // If the keyup event was fired, but the enter key was not pressed
        if ((typeof event !== 'undefined') && event.type === 'keyup' && event.key !== 'Enter') {

            // Do not proceed
            return;

        }

        // Check that a transition is not already running
        if (!transitioningBetweenModes) {

            switch (true) {

                // If the user now has light mode set as the preference on their machine after a change event
                case ((typeof event !== 'undefined') && event.type === 'change' && window.matchMedia('(prefers-color-scheme: light)').matches && isDarkModeActive):
                // If the user has pressed the enter key while the toggleSwitch is in focus dark mode is active when
                case ((typeof event !== 'undefined') && event.type === 'keyup' && event.key === 'Enter' && isDarkModeActive):
                // If the user has clicked the toggleSwitch while dark mode is active
                case ((typeof event !== 'undefined') && event.type === 'click' && isDarkModeActive):
                // If the three cases above are not true, but dark mode is active
                case ((typeof event === 'undefined') && isDarkModeActive):

                    // Flip switch during transition
                    transitioningBetweenModes = true;

                    // Switch the UI state to light theme
                    document.body.classList.remove('theme-dark');
                    document.body.classList.add('theme-default');
                    isDarkModeActive = false;
                    break;

                // If the user now has dark mode set as the preference on their machine after a change event
                case ((typeof event !== 'undefined') && event.type === 'change' && window.matchMedia('(prefers-color-scheme: dark)').matches && !isDarkModeActive):
                // If the user has pressed the enter key while the toggleSwitch is in focus and light mode is active
                case ((typeof event !== 'undefined') && event.type === 'keyup' && event.key === 'Enter' && !isDarkModeActive):
                // If the user has clicked the toggle switch while light mode is active
                case ((typeof event !== 'undefined') && event.type === 'click' && !isDarkModeActive):
                // If the two cases above are not true, but light mode is active
                case ((typeof event === 'undefined') && !isDarkModeActive):

                    // Flip switch during transition
                    transitioningBetweenModes = true;

                    // Switch the UI state to dark theme
                    document.body.classList.add('theme-dark');
                    document.body.classList.remove('theme-default');
                    isDarkModeActive = true;
                    break;

                default:
                    return;

            }

            // Wait for the 250ms css transition to complete before a user can click the toggle again
            setTimeout(function () {

                // Flip switch now that transition is complete
                transitioningBetweenModes = false;

            }, 250);

        }

    }

    return themeToggle;

}

var mi_themeToggle = instantinateThemeToggle();